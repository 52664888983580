<template>
  <div class="PurchaseAmountReport">
    <PageHeader title="采购金额报表" />
    <div class="searchView  flex a-center m-tb-10 j-between p-lr-20">
      <div class="flex">
        <el-popover
          v-model="visible"
          class="OrderDropDown"
          placement="bottom"
          trigger="manual"
          :width="80"
          popper-class="test_pop_view"
        >
          <div class="flex column">
            <div class="p-bottom-10 cursor" @click="selectType(1)">按订单查询</div>
            <div class="cursor" @click="selectType(2)">按物料查询</div>
          </div>
          <div slot="reference" class="out white bg cursor p-tb-5 min-border-right flex a-center f14 pointer" @click="visible = !visible">
            <div>{{ formSearch.ReportType === 1 ? '按订单查询' : '按物料查询' }}</div>
            <i class="el-icon-caret-bottom" />
          </div>
        </el-popover>
      </div>
      <div class="flex" @click="() => $refs.FieldManagement.openSetFieldManagement()">
        <div class="out cursor p-lr-10 p-tb-5 min-border flex a-center f14 m-lr-10">
          <i class="el-icon-setting f15 p-right-5" />   字段管理
        </div>
      </div>
      <div class="flex">
        <!-- <div class="out p-lr-10 p-tb-5 min-border flex a-center f12 m-right-10">
          <i class="el-icon-setting f15 p-right-5" />   字段管理
        </div> -->
      </div>
      <div class="flex m-left-10  SearchBox_30" style="flex:1">
        <el-input v-model="formSearch.KeyWords" size="medium" placeholder="可通过订单号、终端名称搜索" class="rule-input-edit">
          <template slot="prepend">
            <div class="pointer">
              <i class="el-icon-s-operation" />
              <span>高级搜索</span>
            </div>
          </template>
          <div slot="append" class="cursor" @click="searchGetTableDataAsync">搜  索</div>
        </el-input>
      </div>
      <div v-if="$minCommon.checkMeau('cgjebbexport', userBottons)" class="out white  bg p-lr-10 p-tb-5 min-border-right flex a-center m-left-10  pointer  ModelBttton-white-30" @click="exportExel">
        <i class="el-icon-upload2 f15  p-right-10 " />   导出
      </div>
    </div>
    <div class="m-lr-20">
      <!-- 按订单查询 -->
      <el-table
        v-if="formSearch.ReportType === 1"
        ref="multipleTable"
        v-loading="tableLoading"
        height="calc(100vh - 325px)"
        :header-cell-style="{ background: 'rgb(247,248,252)' }"
        :data="tableData"
        tooltip-effect="dark"
        show-header
        row-key="IDX"
        border
        :fit="true"
        @select-all="selectAll"
        @select="select"
      >
        <af-table-column fixed="left" type="selection" width="40" />
        <template v-for="(item,index) in tableListJson.PurchaseAmountReport1">
          <el-table-column
            v-if="item.isChecked && item.prop !== 'AgentCompanyName' && item.prop !== 'WriteTime'"
            :key="index"
            :label="item.label"
            :prop="item.prop"
            show-overflow-tooltip
            width="auto"
          />

          <!--          订单创建时间-->
          <el-table-column
            v-if="item.isChecked && item.prop === 'WriteTime'"
            :key="index"
            :label="item.label"
            :prop="item.prop"
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              {{ $minCommon.formatDate(new Date(scope.row.WriteTime), 'yyyy-MM-dd hh:mm') }}
            </template>
          </el-table-column>
          <el-table-column
            v-if="item.isChecked && item.prop === 'AgentCompanyName'"
            :key="index"
            :label="item.label"
            :prop="item.prop"
            show-overflow-tooltip
          >
            <!-- 买方 -->
            <template slot-scope="scope">
              {{ scope.row.AgentCompanyName ? scope.row.AgentCompanyName :scope.row.CompanyName }}
            </template>
          </el-table-column>
        </template>
        <el-table-column prop="MaterialInvCode" label="操作" show-overflow-tooltip fixed="right" width="50">
          <template slot-scope="scope">
            <el-link v-if="$minCommon.checkMeau('cgjebbview', userBottons)" @click="viewOrder(scope.row)">查看</el-link>
          </template>
        </el-table-column>
      </el-table>
      <!-- 按物料查询 -->
      <el-table
        v-if="formSearch.ReportType === 2"
        ref="multipleTable"
        v-loading="tableLoading"
        class="TableModel5"
        :header-cell-style="{ background: 'rgb(247,248,252)' }"
        :data="tableData"
        tooltip-effect="dark"
        show-header
        :max-height="$minCommon.SetTableHeight(340)"
        row-key="IDX"
        border
        :fit="true"
        @select-all="selectAll"
        @select="select"
      >
        <af-table-column fixed="left" type="selection" width="40" />
        <template v-for="(item,index) in tableListJson.PurchaseAmountReport2">
          <el-table-column
            v-if="item.isChecked && item.prop !== 'WriteTime' && item.prop !== 'ExpiredDate' "
            :key="index"
            :label="item.label"
            :prop="item.prop"
            width="120px"
            show-overflow-tooltip
          />
          <!--          订单创建时间-->
          <el-table-column
            v-if="item.isChecked && item.prop === 'WriteTime'"
            :key="index"
            :label="item.label"
            :prop="item.prop"
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              {{ $minCommon.formatDate(new Date(scope.row.WriteTime), 'yyyy-MM-dd hh:mm') }}
            </template>
          </el-table-column>
          <!--          效期-->

          <el-table-column
            v-if="item.isChecked && item.prop === 'ExpiredDate'"
            :key="index"
            :label="item.label"
            :prop="item.prop"
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              {{ $minCommon.setDateFormate(scope.row.ExpiredDate) }}
            </template>
          </el-table-column>
          <!-- <el-table-column
            v-if="item.isChecked && item.prop === 'AgentCompanyName'"
            :key="index"
            :label="item.label"
            :prop="item.prop"
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              {{ scope.row.AgentCompanyName ? scope.row.AgentCompanyName :scope.row.CompanyName }}
            </template>
          </el-table-column> -->
        </template>
      </el-table>
      <div class="pagination-container">
        <el-pagination
          :ref="paginationName"
          :total="tableTotalSize"
          :pager-count="pagerCount"
          :page-size="tablePageSize"
          :current-page.sync="currentPage"
          :background="tableBackground"
          layout="prev, pager, next, jumper"
          @current-change="handleCurrentChange($event)"
        />
      </div>
    </div>
    <!-- 导出 -->
    <ExportLoading ref="ExportLoading" />
    <!-- 字段搜索 -->
    <FieldManagement ref="FieldManagement" :select-type="formSearch.ReportType + '' + formSearch.OrderType" action-name="GetSalesOrderPriceReport" :list=" formSearch.ReportType ===1 ? tableListJson.PurchaseAmountReport1 : tableListJson.PurchaseAmountReport2" :name="formSearch.ReportType ===1 ? 'PurchaseAmountReport1' :'PurchaseAmountReport2'" :change-list-table="changeListTable" />
  </div>
</template>
<script>
import Index from '@/minxin/report-center/PurchaseAmountReport'

export default Index
</script>
<style lang="scss" scoped>
@import '@/style/search.scss';
</style>
